import React from 'react';
import { HOST_PREFIX } from '../../Network/ApiPaths';

class ProductsComponent extends React.Component {
  constructor(props) {
    super();
  }
  render() {
    return (
      <section className="client no-padding-top">
        <div className="container-fluid">
          <div className="row">
            <div className="card-body text-center">
              <div
                id="myModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                className="modal fade text-left"
              >
                <div role="document" className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 id="exampleModalLabel" className="modal-title">
                        Add Product
                        {/* &nbsp;
                    {this.props.data
                      ? this.props.data.map((p) => p.info.category_id)[0]
                      : null} */}
                      </h4>
                      <button
                        type="button"
                        data-dismiss="modal"
                        aria-label="Close"
                        className="close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <form
                        ref={(el) =>
                          this.props.reset === false
                            ? (this.myFormRef1 = el)
                            : this.myFormRef1.reset()
                        }
                      >
                        <div className="form-group">
                          <label for="name">Name</label>
                          <input
                            type="text"
                            pattern={['(?=.*[A-Z])']}
                            //   export const validEmail = new RegExp(
                            //     '^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'
                            //  );

                            // let result = {re.match(r,"[a-zA-z]+", text)}
                            name="newName"
                            onChange={this.props.fieldChange}
                            // type="email"
                            placeholder="name"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group">
                          <label>Type</label>
                          <input
                            list="types"
                            name="newType"
                            onChange={this.props.fieldChange}
                            className="form-control mb-3"
                          />
                          {/* <datalist id="types">
                        {this.props.data
                          ? this.props.data.map((p) => (
                              <option value={p.info.food_type}></option>
                            ))
                          : null}
                      </datalist> */}
                          <datalist id="types">
                            <option value="VEG"></option>
                            <option value="NON-VEG"></option>
                            <option value="NONE"></option>
                          </datalist>
                          {/* <span id="contactError" className="descriptionError">
                          Enter Contact information{" "}
                        </span> */}
                          {/* </div> */}
                        </div>
                        <div className="form-group">
                          <label>Description</label>
                          <input
                            name="newDesc"
                            onChange={this.props.fieldChange} // type="email"
                            placeholder="description"
                            className="form-control"
                          />
                        </div>
                        {/* <div className="form-group">
                      <label>Store</label>
                      <input
                        name="newStore"
                        onChange={this.props.fieldChange} // type="email"
                        placeholder="store"
                        className="form-control"
                      />
                    </div> */}

                        {/* <div className="form-group">
                      <label>Store</label>
                      <input
                        list="stores"
                        name="newStore"
                        onChange={this.props.fieldChange}
                        className="form-control mb-3"
                      />
                      <datalist id="stores">
                        {this.props.data
                          ? this.props.data.map((p, index) => (
                              <option
                                key={index}
                                value={p.info.store_id}
                              ></option>
                            ))
                          : null}
                      </datalist>
                    </div> */}

                        <div className="form-group">
                          <label>Tax</label>
                          <input
                            name="newTax"
                            type="number"
                            min="0"
                            max="99"
                            onChange={this.props.fieldChange} // type="email"
                            placeholder="tax %"
                            className="form-control"
                          />
                        </div>
                        {/* <div className="form-group row">
                      <div className="col-sm-9">
                        <div>
                          <input
                            id="option"
                            type="checkbox"
                            value=""
                            defaultChecked
                          />
                          <label htmlFor="option"> Active</label>
                        </div>
                      </div>
                    </div> */}
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss={
                              this.props.new === true ? 'modal' : null
                            }
                            onClick={this.props.addNewProduct}
                          >
                            Add Product
                          </button>
                        </div>
                        {/* <div className="form-group" onClick={this.props.addNewProduct}>
                      <input
                        type="submit"
                        value="ADD"
                        className="btn btn-primary"
                      />
                    </div> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body text-center">
              <div
                id="myModal2"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                className="modal fade text-left"
              >
                <div role="document" className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 id="exampleModalLabel" className="modal-title">
                        Edit Details
                      </h4>
                      <button
                        type="button"
                        data-dismiss="modal"
                        aria-label="Close"
                        className="close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>

                    <div className="modal-body">
                      {this.props.itemDefaultDetails !== '' ? (
                        <form
                          ref={(el) =>
                            this.props.reset === false
                              ? (this.myFormRef = el)
                              : this.myFormRef.reset()
                          }
                          className="form-horizontal"
                        >
                          <div className="form-group row">
                            <label
                              htmlFor="fileInput"
                              className="col-sm-3 form-control-label"
                            >
                              Upload Image
                            </label>
                            <div className="col-sm-9">
                              <input
                                id="fileInput"
                                type="file"
                                className="form-control-file"
                              />
                            </div>
                          </div>
                          {/* <div className="form-group row">
                            <label className="col-sm-3 form-control-label">
                              Store
                            </label>
                            <div className="col-sm-9">
                              <input
                                id="inputHorizontalSuccess"
                                // type="email"
                                placeholder="New Store Id"
                                className="form-control form-control-success"
                                name="updatedStore"
                                onChange={this.props.fieldChange}
                              />
                              <small className="form-text">
                                {this.props.itemDefaultDetails.info.store_id}
                              </small>
                            </div>
                          </div> */}

                          <div className="form-group row">
                            <label className="col-sm-3 form-control-label">
                              Name
                            </label>

                            <div className="col-sm-9">
                              <input
                                type="text"
                                id="inputHorizontalSuccess"
                                // placeholder="New Name"
                                className="form-control form-control-success"
                                name="updatedName"
                                // placeholder={this.props.itemDefaultDetails.info.name}
                                // defaultValue={this.props.itemDefaultDetails.info.name}
                                // value={this.props.itemDefaultDetails.info.name}
                                onChange={this.props.fieldChange}
                              />
                              <small className="form-text">
                                {this.props.itemDefaultDetails.info.name}
                              </small>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-sm-3 form-control-label">
                              Type
                            </label>
                            <div className="col-sm-9">
                              <input
                                id="inputHorizontalSuccess"
                                // type="email"
                                placeholder="New Type"
                                className="form-control form-control-success"
                                name="updatedType"
                                onChange={this.props.fieldChange}
                              />
                              <small className="form-text">
                                {this.props.itemDefaultDetails.info.type}
                              </small>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-sm-3 form-control-label">
                              Description
                            </label>
                            <div className="col-sm-9">
                              <input
                                id="inputHorizontalSuccess"
                                // type="email"
                                placeholder="New Description"
                                className="form-control form-control-success"
                                name="updatedDesc"
                                onChange={this.props.fieldChange}
                              />
                              <small className="form-text">
                                {this.props.itemDefaultDetails.info.description}
                              </small>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-sm-3 form-control-label">
                              Tax
                            </label>
                            <div className="col-sm-9">
                              <input
                                id="inputHorizontalSuccess"
                                // type="email"
                                placeholder="New tax"
                                className="form-control form-control-success"
                                name="updatedTax"
                                onChange={this.props.fieldChange}
                              />
                              <small className="form-text">
                                {this.props.itemDefaultDetails.info.tax}
                              </small>
                            </div>
                          </div>
                          {/* <div className="form-group row">
                      <div className="col-sm-9 offset-sm-3">
                        <input
                          type="submit"
                          value="Signin"
                          className="btn btn-primary"
                        />
                      </div>
                    </div> */}
                        </form>
                      ) : null}

                      {/* 
                  <form>
                    <div className="form-group">
                      <label>Upload Image</label>
                      <input
                        onChange={this.props.nameChange}
                        type="email"
                        placeholder="product name"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label>Select Store</label>
                      <input
                        onChange={this.props.nameChange}
                        type="email"
                        placeholder="product name"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label>Name</label>
                      <input
                        onChange={this.props.nameChange}
                        type="email"
                        placeholder="product name"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label>Type</label>
                      <input
                        onChange={this.props.nameChange}
                        type="email"
                        placeholder="product type"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label>Description</label>
                      <input
                        onChange={this.props.nameChange}
                        type="email"
                        placeholder="product price"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label>Tax</label>
                      <input
                        onChange={this.props.nameChange}
                        type="email"
                        placeholder="offer %"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-9">
                        <div>
                          <input
                            id="option"
                            type="checkbox"
                            value=""
                            defaultChecked
                          />
                          <label htmlFor="option"> Active</label>
                        </div>
                      </div>
                    </div>
                     <hr />
                    <hr />
                    <div className="form-group">
                      <label>Prices</label>
                    </div>
                    <div className="form-group" onClick={this.props.add}>
                      <input
                        type="submit"
                        value="Add Prices"
                        className="btn btn-primary"
                      />
                      <span>&nbsp;</span>
                      <input
                        type="submit"
                        value="Delete"
                        className="btn btn-primary"
                      />
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-9">
                        <label>Prices</label>
                        <input
                          id="option"
                          type="checkbox"
                          value=""
                          defaultChecked
                        />
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <label htmlFor="option"> Active</label>
                      </div>
                      <div className="col-sm-9">
                        <label>Prices</label>
                        <input
                          id="option"
                          type="checkbox"
                          value=""
                          defaultChecked
                        />
                        <label htmlFor="option"> Active</label>
                      </div>
                    </div>  
                  </form> */}
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-dismiss={
                          this.props.productUpdated === true ? 'modal' : null
                        }
                        onClick={this.props.onUpdateDetails}
                      >
                        Update details
                      </button>
                      {/* <button type="button" className="btn btn-primary">
                    Delete Product
                  </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body text-center">
              <div
                id="myModal3"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                className="modal fade text-left"
              >
                <div role="document" className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 id="exampleModalLabel" className="modal-title">
                        Add Price
                      </h4>
                      <button
                        type="button"
                        data-dismiss="modal"
                        aria-label="Close"
                        className="close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <form
                        ref={(el) =>
                          this.props.reset === false
                            ? (this.myFormRef = el)
                            : this.myFormRef.reset()
                        }
                      >
                        <div className="form-group">
                          <label>Quantity</label>
                          <input
                            placeholder="quantity"
                            className="form-control"
                            type="number"
                            min="0"
                            name="newPriceQuantity"
                            onChange={this.props.fieldChange}
                          />
                        </div>
                        <div className="form-group">
                          <label>Measure</label>
                          <input
                            list="measures"
                            name="newPriceMeasure"
                            onChange={this.props.fieldChange}
                            className="form-control mb-3"
                          />
                          <datalist id="measures">
                            <option value="Regular"></option>
                            <option value="Medium"></option>
                            <option value="300ml"></option>
                            <option value="400ml"></option>
                            <option value="500ml"></option>
                            {/* <option value="GRM"></option>
                        <option value="KG"></option>
                        <option value="LTR"></option>
                        <option value="Pieces"></option>
                        <option value="Meters"></option> */}
                          </datalist>
                          {/* <input
                        type="email"
                        name="newPriceMeasure"
                        onChange={this.props.fieldChange}
                        placeholder="product name"
                        className="form-control"
                      /> */}
                        </div>
                        <div className="form-group">
                          <label>Price</label>
                          <input
                            type="number"
                            min="1"
                            name="newPrice"
                            onChange={this.props.fieldChange}
                            placeholder="product type"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group">
                          <label>Offer%</label>
                          <input
                            type="number"
                            min="1"
                            name="newPriceOffer"
                            onChange={this.props.fieldChange}
                            placeholder="product price"
                            className="form-control"
                          />
                        </div>
                      </form>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        data-dismiss="modal"
                        className="btn btn-secondary"
                      >
                        Close
                      </button>
                      <button
                        data-dismiss={
                          this.props.priceAdded === true ? 'modal' : null
                        }
                        type="button"
                        className="btn btn-primary"
                        onClick={this.props.onAddNewPrice}
                      >
                        ADD price
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body text-center">
              <div
                id="myModal4"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                className="modal fade text-left"
              >
                <div role="document" className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 id="exampleModalLabel" className="modal-title">
                        Edit Price
                      </h4>
                      <button
                        type="button"
                        data-dismiss="modal"
                        aria-label="Close"
                        className="close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      {this.props.itemDefaultPrices !== '' ? (
                        <form
                          ref={(el) =>
                            this.props.reset === false
                              ? (this.myFormRef = el)
                              : this.myFormRef.reset()
                          }
                        >
                          <div className="form-group row">
                            <label className="col-sm-3 form-control-label">
                              Quantity
                            </label>

                            <div className="col-sm-9">
                              <input
                                type="text"
                                id="inputHorizontalSuccess"
                                className="form-control form-control-success"
                                name="priceQuantity"
                                onChange={this.props.fieldChange}
                                placeholder={
                                  this.props.itemDefaultPrices.quantity
                                }
                              />
                              <small className="form-text">
                                {this.props.itemDefaultPrices.quantity}
                              </small>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-sm-3 form-control-label">
                              Measure
                            </label>

                            <div className="col-sm-9">
                              <input
                                type="text"
                                id="inputHorizontalSuccess"
                                className="form-control form-control-success"
                                name="priceMeasure"
                                onChange={this.props.fieldChange}
                              />
                              <small className="form-text">
                                {this.props.itemDefaultPrices.measure}
                              </small>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-sm-3 form-control-label">
                              Price
                            </label>

                            <div className="col-sm-9">
                              <input
                                type="text"
                                id="inputHorizontalSuccess"
                                className="form-control form-control-success"
                                name="pricePrice"
                                onChange={this.props.fieldChange}
                              />
                              <small className="form-text">
                                {this.props.itemDefaultPrices.price}
                              </small>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-sm-3 form-control-label">
                              Offer%
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                id="inputHorizontalSuccess"
                                className="form-control form-control-success"
                                name="priceOffer"
                                onChange={this.props.fieldChange}
                              />
                              <small className="form-text">
                                {this.props.itemDefaultPrices.offer}
                              </small>
                            </div>
                          </div>
                        </form>
                      ) : null}
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={this.props.onUpdatePrices}
                        data-dismiss={
                          this.props.priceUpdated === true ? 'modal' : null
                        }
                      >
                        Update Price
                      </button>
                      <button
                        data-dismiss={
                          this.props.priceDeleted === true ? 'modal' : null
                        }
                        type="button"
                        className="btn btn-primary"
                        onClick={this.props.deletePrice}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body text-center">
              <div
                id="myModal5"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                className="modal fade text-left"
              >
                <div role="document" className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 id="exampleModalLabel" className="modal-title">
                        Do you Want to Delete This Product
                      </h4>
                      <button
                        type="button"
                        data-dismiss="modal"
                        aria-label="Close"
                        className="close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    {/* <div className="modal-body">
                  <form>
                    <div className="form-group">
                      <label>Quantity</label>
                      <input
                        onChange={this.props.nameChange}
                        type="email"
                        placeholder="product name"
                        className="form-control"
                      />
                    </div>
                  </form>
                </div> */}
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-dismiss={
                          this.props.productDeleted === true ? 'modal' : null
                        }
                        onClick={this.props.onProductDelete}
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        data-dismiss="modal"
                        className="btn btn-secondary"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="card">
                <div className="card-close">
                  <div className="dropdown">
                    {
                      // this.props.data ? (
                      // this.props.data.length === 0 &&
                      this.props.new === false ? null : (
                        <button
                          type="button"
                          data-toggle="modal"
                          data-target="#myModal"
                          className="btn btn-primary"
                        >
                          Add Product
                        </button>
                      )
                      // ) : null
                    }
                    <span>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>

                    {/* <button
                  type="button"
                  id="closeCard4"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className="dropdown-toggle"
                >
                  <i className="fa fa-ellipsis-v"></i>
                </button>
                <div
                  aria-labelledby="closeCard4"
                  className="dropdown-menu dropdown-menu-right has-shadow"
                >
                  <a href="#profile" className="dropdown-item remove">
                    <i className="fa fa-times"></i>Close
                  </a>
                  <a href="#profile" className="dropdown-item edit">
                    <i className="fa fa-gear"></i>Edit
                  </a>
                </div> */}
                  </div>
                </div>
                <th>
                  <div className="card-header d-flex align-items-center">
                    <h3 className="h4">Products</h3>
                    <div className="col-sm-3 offset-sm-0">
                      <select
                        multiple=""
                        className="form-control"
                        style={{
                          backgroundColor: '#379392',
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        onChange={(p) =>
                          this.props.selectedCat(p.target.value, p.name)
                        }
                      >
                        <option value="" hidden>
                          SELECT CATEGORY{' '}
                        </option>
                        {this.props.catData
                          ? this.props.catData.map((p, index) => (
                              <option
                                style={{ color: 'white' }}
                                // onClick={() => this.props.selectedCat(p.id, p.name) }
                                value={p.id}
                              >
                                {p.name}
                              </option>
                            ))
                          : null}
                      </select>
                    </div>
                  </div>
                </th>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped table-sm">
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Quantity/Measure</th>
                          <th>Price</th>
                          <th>Offer%</th>
                          <th>Offer Price</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                      {/* <tbody> */}
                      {this.props.data
                        ? this.props.data.map((p, index) => (
                            <tbody key={index}>
                              <tr style={{ backgroundColor: '#F0FFFF' }}>
                                <th scope="col">&nbsp;&nbsp;{p.info.name}</th>
                                <th scope="col">Tax({p.info.tax}%)</th>
                                {/* <th >{p.info.image_path}</th> */}
                                {p.info.image_path ? (
                                  <th scope="col">
                                    <a
                                      rel="noopener noreferrer"
                                      target="_blank"
                                      href={
                                        HOST_PREFIX +
                                        'images/menu/' +
                                        p.info.id +
                                        '/' +
                                        p.info.image_path
                                      }
                                    >
                                      {p.info.image_path}
                                    </a>
                                  </th>
                                ) : (
                                  <th scope="col">
                                    <div>
                                      <input
                                        type="file"
                                        style={{ width: ' 102px' }}
                                        onChange={(e) =>
                                          this.props.handleChange(e, p.info.id)
                                        }
                                      />
                                    </div>
                                  </th>
                                )}
                                <th scope="col">{p.info.is_deleted}</th>
                                <th scope="col">&nbsp;</th>
                                <th scope="col">
                                  <span
                                    data-toggle="modal"
                                    data-target="#myModal2"
                                    onClick={() =>
                                      this.props.onProductDetails(p)
                                    }
                                  >
                                    Details
                                  </span>
                                  &nbsp;&nbsp;
                                  <span
                                    data-toggle="modal"
                                    data-target="#myModal3"
                                    onClick={() =>
                                      this.props.onProductPrices(p)
                                    }
                                  >
                                    prices
                                  </span>
                                  &nbsp;&nbsp;
                                  <span
                                    data-toggle="modal"
                                    data-target="#myModal5"
                                    className="fa fa-trash-o fa-lg"
                                    onClick={() =>
                                      this.props.onProductTriggerDelete(p)
                                    }
                                  ></span>
                                </th>
                              </tr>
                              {p.prices ? (
                                p.prices.map((q, index) => (
                                  <tr key={index} style={{ color: 'black' }}>
                                    <td className="pl-4">{index + 1}</td>
                                    <td>
                                      {q.quantity}&nbsp;{q.measure}
                                    </td>
                                    <td>{q.price}</td>
                                    <td>{q.offer}</td>
                                    <td>{q.offer_price}</td>
                                    <td
                                      data-toggle="modal"
                                      data-target="#myModal4"
                                      onClick={() =>
                                        this.props.onPriceTriggerDelete(q)
                                      }
                                    >
                                      EDIT/DELETE
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                </tr>
                              )}
                            </tbody>
                          ))
                        : null}
                      {/* {this.props.data
                      ? this.props.data.map((p) => (
                          <>
                            <tr>
                              <td>hgfdjhdhzdffhbfgnfgnx</td>
                            </tr>
                            {p.prices
                              ? p.prices.map((p, index) => (
                                  <tr>
                                    <th key={index} scope="row">
                                      {index + 1}
                                    </th>
                                    <td key={index} scope="row">
                                      {p.id}
                                    </td>
                                    <td key={index} scope="row">
                                      {p.quantity}
                                    </td>
                                    <td key={index} scope="row">
                                      {p.measure}
                                    </td>
                                    <td key={index} scope="row">
                                      {p.price}
                                    </td>
                                  </tr>
                                ))
                              : null}
                          </>
                        ))
                      : null} */}
                      {/* </tbody> */}
                    </table>
                    <div className="input-group-prepend">
                      Page:<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      {/* {this.props.data.map((p, index) => (
                    <span
                      className="input-group-text"
                      onClick={() => this.props.next(index)}
                    >
                      {index}
                    </span>
                  ))} */}
                      <span
                        className="input-group-text"
                        onClick={() => this.props.next(0)}
                      >
                        1
                      </span>
                      <span
                        className="input-group-text"
                        onClick={() => this.props.next(10)}
                      >
                        2
                      </span>
                      <span
                        className="input-group-text"
                        onClick={() => this.props.next(20)}
                      >
                        3
                      </span>
                      <span
                        className="input-group-text"
                        onClick={() => this.props.next(30)}
                      >
                        4
                      </span>
                      <span
                        className="input-group-text"
                        onClick={() => this.props.next(40)}
                      >
                        5
                      </span>
                      <span
                        className="input-group-text"
                        onClick={() => this.props.next(50)}
                      >
                        6
                      </span>
                      <span
                        className="input-group-text"
                        onClick={() => this.props.next(60)}
                      >
                        7
                      </span>
                      <span
                        className="input-group-text"
                        onClick={() => this.props.next(70)}
                      >
                        8
                      </span>
                      <span
                        className="input-group-text"
                        onClick={() => this.props.next(80)}
                      >
                        9
                      </span>
                      <span
                        className="input-group-text"
                        onClick={() => this.props.next(90)}
                      >
                        10
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default ProductsComponent;
