import React from "react";
import ProductsComponent from "./ProductsComponent";
import {
  CATEGORY_LIST,
  ADD_PRODUCT,
  ADD_PRICE,
  UPDATE_PRODUCT_IMAGE,
  MENU_LIST,
  // HOST,
} from "../../Network/ApiPaths";
// import postDataResponse from "../../Network/PostDataResponse";
// import getResponse from "../../Network/GetDataResponse";
import Uri from "../../Network/Uri";
import postClientDataResponse from "../../Network/PostClientDataResponse";
import getResponseClient from "../../Network/GetClientDataResponse";

let storeFresh;
let clientHost;

class ProductsContainer extends React.Component {
  constructor(props) {
    super();

    const client = window.localStorage.getItem("clientAccess");
    storeFresh = JSON.parse(client);
    if (storeFresh !== null) {
      clientHost = JSON.parse(window.localStorage.getItem("clientAccess")).host;
    }

    this.state = {
      reset: false,
      products: [],
      categories: [],
      selectedCatId: "",
      selectedSubCatId: "",
      collapse: false,
      editedCat: false,
      cName: "all",
      // onUpdateDetails: "",
      name: "",
      store: "",
      type: "",
      description: "",
      tax: "",
      itemDefaultDetails: "",
      itemDefaultPrices: "",
      newName: "",
      newStore: "",
      newType: "",
      newDesc: "",
      newTax: "",
      updatedName: "",
      updatedStore: "",
      updatedType: "",
      updatedDesc: "",
      updatedTax: "",
      dID: "",
      dPID: "",
      newPriceQuantity: "",
      newPriceMeasure: "",
      newPrice: "",
      newPriceOffer: "",
      newPID: "",
      priceQuantity: "",
      priceMeasure: "",
      pricePrice: "",
      priceOffer: "",
      new: false,
      productDeleted: false,
      productUpdated: false,
      priceDeleted: false,
      priceUpdated: false,
      priceAdded: false,
    };
  }
  getProducts = () => {
    var apiPath = new Uri(clientHost + MENU_LIST).addQueryParam(
      "storeid",
      storeFresh.storeId
    );
    // .addQueryParam("limit", "10")
    // .addQueryParam("offset", "0");
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        // this.setState({
        //   reset: false,
        // });
        // if (onSuccessResponse.status === true) {
        this.setState({
          products: onSuccessResponse.result.filter(
            (q) => q.info.category_id === this.state.selectedCatId
          ),
          reset: false,
          // collapse: false,
          // new: true,
          // priceAdded: true,
          // priceDeleted: true,
          // productDeleted: true,
        });
        // } else {
        //   this.setState({
        //     products: onSuccessResponse.result.filter(
        //       (q) => q.info.category_id === this.state.presentCatId
        //     ),
        //     // collapse: false,
        //     // new: true,
        //     // priceAdded: true,
        //     // priceDeleted: true,
        //     // productDeleted: true,
        //   });
        // }
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  };
  selectedCatID = (sCatId, sCatName) => {
    this.setState({
      selectedCatId: sCatId,
      cName: sCatName,
      collapse: true,
    });
    var apiPath = new Uri(clientHost + MENU_LIST).addQueryParam(
      "storeid",
      storeFresh.storeId
    );
    // ?category_id=${sCatId}&limit=10&offset=0`
    // ).addQueryParam("store_id", storeFresh.storeId);
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        if (onSuccessResponse.status === true) {
          this.setState({
            products: onSuccessResponse.result.filter(
              (q) => q.info.category_id === sCatId
            ),
            collapse: false,
            new: true,
          });
        } else {
          this.setState({
            products: onSuccessResponse.result.filter(
              (q) => q.info.category_id === sCatId
            ),
            collapse: false,
            new: true,
          });
        }
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  };
  next = (offset) => {
    if (this.state.selectedCatId === "") {
      this.setState({
        products: [],
      });
    } else {
      var apiPath = new Uri(
        clientHost +
          `Menu/listmenu?category_id=${this.state.selectedCatId}&limit=10&offset=${offset}`
      );
      getResponseClient(
        apiPath,
        (onSuccessResponse) => {
          this.setState({
            products: onSuccessResponse.result,
          });
        },
        (onError) => {
          // alert(onError.message);
        }
      );
    }
  };
  fieldChange = (e) => this.setState({ [e.target.name]: e.target.value });
  onUpdatePrices = (e) => {
    let priceUpdatedQuantity;
    let priceUpdatedMeasure;
    let priceUpdatedPrice;
    let priceUpdatedOffer;
    if (this.state.priceQuantity === "") {
      priceUpdatedQuantity = this.state.itemDefaultPrices.quantity;
    } else {
      priceUpdatedQuantity = this.state.priceQuantity;
    }
    if (this.state.priceMeasure === "") {
      priceUpdatedMeasure = this.state.itemDefaultPrices.measure;
    } else {
      priceUpdatedMeasure = this.state.priceMeasure;
    }
    if (this.state.pricePrice === "") {
      priceUpdatedPrice = this.state.itemDefaultPrices.price;
    } else {
      priceUpdatedPrice = this.state.pricePrice;
    }
    if (this.state.priceOffer === "") {
      priceUpdatedOffer = this.state.itemDefaultPrices.offer;
    } else {
      priceUpdatedOffer = this.state.priceOffer;
    }
    if (
      (this.state.priceQuantity === "" ||
        this.state.priceQuantity === this.state.itemDefaultPrices.quantity) &&
      (this.state.priceMeasure === "" ||
        this.state.priceMeasure === this.state.itemDefaultPrices.measure) &&
      (this.state.pricePrice === "" ||
        this.state.pricePrice === this.state.itemDefaultPrices.price) &&
      (this.state.priceOffer === "" ||
        this.state.priceOffer === this.state.itemDefaultPrices.offer)
    ) {
      alert("no changes to update");
    } else {
      // this.setState({
      //   priceUpdated: true,
      // });
      // POST API WITH DATA
      const requestBody = {
        id: this.state.itemDefaultPrices.id,
        quantity: priceUpdatedQuantity,
        measure: priceUpdatedMeasure,
        price: priceUpdatedPrice,
        offer: priceUpdatedOffer,
        product_id: this.state.itemDefaultPrices.product_id,
        storeId: storeFresh.storeId,
      };
      postClientDataResponse(
        clientHost + ADD_PRICE,
        storeFresh.key,
        requestBody,
        (onSuccessResponse) => {
          this.getProducts(this.state.selectedCatId);
          this.setState({
            reset: true,
            priceUpdated: true,
          });
          // if (onSuccessResponse.status === true) {
          //   this.setState({
          //     products: onSuccessResponse.result.filter(
          //       (q) => q.info.category_id === presentCatId
          //     ),
          //     collapse: false,
          //     new: true,
          //   });
          // } else {
          //   this.setState({
          //     products: onSuccessResponse.result.filter(
          //       (q) => q.info.category_id === presentCatId
          //     ),
          //     collapse: false,
          //     new: true,
          //   });
          // }
        },
        (onError) => {
          // alert(onError);
        }
      );
    }

    // if (
    //   this.state.priceQuantity === "" ||
    //   this.state.priceQuantity === null ||
    //   this.state.priceQuantity === undefined
    // ) {
    //   alert("Enter Quantity");
    // } else if (
    //   this.state.priceMeasure === "" ||
    //   this.state.priceMeasure === null ||
    //   this.state.priceMeasure === undefined
    // ) {
    //   alert("Enter Measure");
    // } else if (
    //   this.state.pricePrice === "" ||
    //   this.state.pricePrice === null ||
    //   this.state.pricePrice === undefined
    // ) {
    //   alert("Enter Price");
    // } else if (
    //   this.state.priceOffer === "" ||
    //   this.state.priceOffer === null ||
    //   this.state.priceOffer === undefined
    // ) {
    //   alert("Enter Offer");
    // } else {
    //   // POST API WITH DATA
    //   const requestBody = {
    //     id: this.state.itemDefaultPrices.id,
    //     quantity: this.state.priceQuantity,
    //     measure: this.state.priceMeasure,
    //     price: this.state.pricePrice,
    //     offer: this.state.priceOffer,
    //   };
    //   postClientDataResponse(
    // clientHost +
    //     ADD_PRICE,
    //     requestBody,
    //     (onSuccessResponse) => {
    //       alert(onSuccessResponse);
    //       console.log(onSuccessResponse);
    //     },
    //     (onError) => {
    //       alert(onError);
    //     }
    //   );
    // }
  };
  onUpdateDetails = (e) => {
    let updatedProductName;
    let updatedProductType;
    let updatedProductDesc;
    let updatedProductTax;
    // let updatedProductStore;
    // if (this.state.name === "") {
    //   updatedProductName = this.state.itemDefaultDetails.name;
    // } else {
    updatedProductName = this.state.updatedName;
    // }
    // if (this.state.type === "") {
    //   updatedProductType = this.state.itemDefaultDetails.type;
    // } else {
    updatedProductType = this.state.updatedType;
    // }
    // if (this.state.store === "") {
    //   updatedProductStore = this.state.itemDefaultDetails.store;
    // } else {
    // updatedProductStore = this.state.updatedStore;
    // }
    // if (this.state.description === "") {
    //   updatedProductDesc = this.state.itemDefaultDetails.description;
    // } else {
    updatedProductDesc = this.state.updatedDesc;
    // }
    // if (this.state.tax === "") {
    //   updatedProductTax = this.state.itemDefaultDetails.tax;
    // } else {
    updatedProductTax = this.state.updatedTax;
    // }
    if (
      (updatedProductName === "" || this.state.itemDefaultDetails.name) &&
      (updatedProductType === "" || this.state.itemDefaultDetails.type) &&
      // (updatedProductStore === "" || this.state.itemDefaultDetails.store) &&
      (updatedProductDesc === "" ||
        this.state.itemDefaultDetails.description) &&
      (updatedProductTax === "" || this.state.itemDefaultDetails.tax)
    ) {
      alert("no details are changed");
    } else {
      let productData = this.state.itemDefaultDetails;
      console.log(productData.info.id);
      const requestBody = {
        id: productData.info.id,
        name: updatedProductName,
        // store: updatedProductStore,
        type: updatedProductType,
        description: updatedProductDesc,
        tax: updatedProductTax,
        storeId: storeFresh.storeId,
      };
      postClientDataResponse(
        // clientHost + UPDATE_USER_DETAILS,
        clientHost + ADD_PRODUCT,
        storeFresh.key,
        requestBody,
        (onSuccessResponse) => {
          this.getProducts();
          this.setState({
            reset: true,
            updatedProduct: true,
          });
        }
      );
    }
  };

  // alert(
  //   this.state.itemDefaultDetails +
  //     this.state.updatedName +
  //     this.state.updatedStore +
  //     this.state.updatedType +
  //     this.state.updatedDesc +
  //     this.state.updatedTax
  // );
  // e.preventDefault();
  // alert(this.state.itemDefaultDetails.info.name);
  // alert(this.state.name);
  // this.setState({
  //   store: this.state.store,
  //   name: this.state.name,
  //   type: this.state.type,
  //   description: this.state.description,
  //   tax: this.state.tax,
  // });
  // if (this.state.name === "") {
  //   alert("name not changed");
  // } else {
  //   alert("name changed");
  // }
  // if (
  //   this.state.store === "" ||
  //   this.state.store === null ||
  //   this.state.store === undefined
  // ) {
  //   alert("store");
  // } else {
  // }
  // // POST API WITH DATA
  // const requestBody = {
  //   onUpdateDetails: this.state.onUpdateDetails,
  //   addon_status: "NO",
  //   status: "ACTIVE",
  // };
  // postClientDataResponse(
  // clientHost +
  //   ADD_CATEGORY,
  //   requestBody,
  //   (onSuccessResponse) => {
  //     alert(onSuccessResponse);
  //     console.log(onSuccessResponse);
  //   },
  //   (onError) => {
  //     alert(onError);
  //   }
  // );
  //   this.setState({
  //     reset: true,
  //     productUpdated: true,
  //   });
  //   this.getProducts(this.state.selectedCatID);
  // };

  // onUpdatePrices = (e) => {
  //   // e.preventDefault();
  //   // this.setState({
  //   //   store: this.state.store,
  //   //   name: this.state.name,
  //   //   type: this.state.type,
  //   //   description: this.state.description,
  //   //   tax: this.state.tax,
  //   // });
  // };
  onProductPrices = (e) => {
    this.setState({
      newPID: e.info.id,
    });
    // console.log(e);
  };

  onProductDelete = (e) => {
    this.setState({
      productDeleted: true,
    });
    var apiPath = new Uri(
      clientHost + `Menu/deleteProduct?id=${this.state.dID}`
    ).addQueryParam("storeid", storeFresh.storeId);
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.getProducts(this.state.selectedCatId);
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  };
  // onPriceDelete = (e) => {
  //   var apiPath = new Uri(clientHost +HOST + `Menu/deleteProduct?id=${this.state.dID}`);
  //    getResponseClient(
  //     apiPath,
  //     (onSuccessResponse) => {
  //       alert(onSuccessResponse);
  //     },
  //     (onError) => {
  //       alert(onError.message);
  //     }
  //   );
  // };
  onProductDetails = (e) => {
    // e.preventDefault();
    this.setState({
      itemDefaultDetails: e,
      store: this.state.store,
      name: this.state.name,
      type: this.state.type,
      description: this.state.description,
      tax: this.state.tax,
    });
  };
  onAddNewProduct = () => {
    if (
      this.state.newName === "" ||
      this.state.newName === null ||
      this.state.newName === undefined
    ) {
      alert("Enter Name");
    } else if (
      this.state.newType === "" ||
      this.state.newType === null ||
      this.state.newType === undefined
    ) {
      alert("Enter Type");
    } else if (
      this.state.newDesc === "" ||
      this.state.newDesc === null ||
      this.state.newDesc === undefined
    ) {
      alert("Enter Description");
    }
    // else if (
    //   this.state.newStore === "" ||
    //   this.state.newStore === null ||
    //   this.state.newStore === undefined
    // ) {
    //   alert("Enter Store");
    // }
    else if (
      this.state.newTax === "" ||
      this.state.newTax === null ||
      this.state.newTax === undefined
    ) {
      alert("Enter Tax Value");
    } else {
      // let productDet = this.state.itemDefaultDetails;
      // POST API WITH DATA
      const requestBody = {
        // sub_category_id: "",
        tax: this.state.newTax,
        category_id: this.state.selectedCatId,
        sub_category_id: "1",
        name: this.state.newName,
        type: this.state.newType,
        description: this.state.newDesc,
        storeId: storeFresh.storeId,
      };

      postClientDataResponse(
        clientHost + ADD_PRODUCT,
        storeFresh.key,
        requestBody,
        (onSuccessResponse) => {
          this.setState({
            reset: true,
          });
          // alert(onSuccessResponse);
          // console.log(onSuccessResponse);
          this.getProducts(this.state.selectedCatId);
        },
        (onError) => {
          //  console.log(onError);
        }
      );
    }
  };
  onProductTriggerDelete = (e) => {
    this.setState({
      dID: e.info.id,
    });
  };
  onPriceTriggerDelete = (e) => {
    // console.log(e);
    this.setState({
      itemDefaultPrices: e,
    });
  };
  deletePrice = () => {
    var apiPath = new Uri(
      clientHost + `Menu/deletePrice?id=${this.state.itemDefaultPrices.id}`
    );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        // alert(onSuccessResponse);
        this.getProducts();
        this.setState({
          priceDeleted: true,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  };
  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  handleChange = (e, id) => {
    // console.log(e.target.files[0].name)\
    let img = e.target.files[0];
    // let reader = new FileReader();
    ////////////////////
    const handleFileRead = async (event) => {
      const file = img;
      const base64 = await this.convertBase64(file);
      const requestBody = {
        id: id,
        image: base64,
      };
      postClientDataResponse(
        clientHost + UPDATE_PRODUCT_IMAGE,
        storeFresh.key,
        requestBody,
        (onSuccessResponse) => {
          this.getProducts();
        },
        (onError) => {
          console.log(onError.message);
        }
      );
    };
    handleFileRead();
  };
  onAddNewPrice = (e) => {
    if (
      this.state.newPriceQuantity === "" ||
      this.state.newPriceQuantity === null ||
      this.state.newPriceQuantity === undefined
    ) {
      alert("Enter Quantity");
    } else if (
      this.state.newPriceMeasure === "" ||
      this.state.newPriceMeasure === null ||
      this.state.newPriceMeasure === undefined
    ) {
      alert("Enter Measure");
    } else if (
      this.state.newPrice === "" ||
      this.state.newPrice === null ||
      this.state.newPrice === undefined
    ) {
      alert("Enter Price");
    } else if (
      this.state.newPriceOffer === "" ||
      this.state.newPriceOffer === null ||
      this.state.newPriceOffer === undefined
    ) {
      alert("Enter Offer");
    } else {
      this.setState({
        priceAdded: true,
      });
      const requestBody = {
        // sub_category_id: "",
        quantity: this.state.newPriceQuantity,
        measure: this.state.newPriceMeasure,
        offer: this.state.newPriceOffer,
        price: this.state.newPrice,
        product_id: this.state.newPID,
        storeId: storeFresh.storeId,
        // store_id: storeFresh.storeId,
      };
      postClientDataResponse(
        clientHost + ADD_PRICE,
        storeFresh.key,
        requestBody,
        (onSuccessResponse) => {
          this.setState({
            reset: true,
          });
          this.getProducts(this.state.selectedCatId);
        },
        (onError) => {
          alert(onError);
        }
      );
    }
  };
  render() {
    return (
      <>
        <ProductsComponent
          data={this.state.products}
          catData={this.state.categories}
          selectedCat={this.selectedCatID}
          catid={this.state.selectedCatId}
          cname={this.state.cName}
          collapse={this.state.collapse}
          next={this.next}
          fieldChange={this.fieldChange}
          onUpdateDetails={this.onUpdateDetails}
          onUpdatePrices={this.onUpdatePrices}
          handleChange={this.handleChange}
          // handleClick={this.handleClick}
          // onUpdatePrice={this.onUpdatePrice}
          onAddPrice={this.onAddPrice}
          deletePrice={this.deletePrice}
          onProductDetails={this.onProductDetails}
          onProductPrices={this.onProductPrices}
          onProductDelete={this.onProductDelete}
          itemDefaultDetails={this.state.itemDefaultDetails}
          itemDefaultPrices={this.state.itemDefaultPrices}
          addNewProduct={this.onAddNewProduct}
          onProductTriggerDelete={this.onProductTriggerDelete}
          onPriceTriggerDelete={this.onPriceTriggerDelete}
          onAddNewPrice={this.onAddNewPrice}
          new={this.state.new}
          productDeleted={this.state.productDeleted}
          productUpdated={this.state.productUpdated}
          priceDeleted={this.state.priceDeleted}
          priceUpdated={this.state.priceUpdated}
          priceAdded={this.state.priceAdded}
          reset={this.state.reset}
        />
      </>
    );
  }
  getCategories = () => {
    var apiPath = new Uri(clientHost + CATEGORY_LIST).addQueryParam(
      "store_id",
      storeFresh.storeId
    );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({ categories: onSuccessResponse.result });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  };
  async componentDidMount() {
    this.getCategories();
    this.getProducts();
  }
}

export default ProductsContainer;
