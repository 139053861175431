import axios from "axios";
import qs from "qs";

export function postClientDataResponse(url, key, requestBody, onSuccess, onError) {
  axios
    .create({
      responseType: "json",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        "x-api-key": key,
      },
    })
    .post(url, qs.stringify(requestBody))
    .then((res) => {  
      if (onSuccess != null) {
        onSuccess(res);
      }
    })
    .catch((err) => {
      if (onError != null) {
        onError(err);
      }
    });
}

export default postClientDataResponse;
