import React from "react";
import { CURRENCY } from "../../Network/ApiPaths";

const CardsComponent = (props) => (
  <>
    <section className="dashboard-counts no-padding-bottom">
      <div className="container-fluid">
        <div className="row bg-white has-shadow">
          <div className="col-xl-4 col-sm-6">
            <div className="item d-flex align-items-center">
              <div className="icon bg-violet">
                <i className="icon-user"></i>
              </div>
              <div className="title">
                <span>Total Sales</span>
                <div className="number" style={{ color: "black" }}>
                  <strong>
                    <i className={CURRENCY} aria-hidden="true"></i>&nbsp;
                    {Math.round(isNaN(props.sales) ? null : props.sales)}
                  </strong>
                </div>
                <div className="progress">
                  <div
                    role="progressbar"
                    style={{ width: "25%", height: "4px" }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    className="progress-bar bg-violet"
                  ></div>
                </div>
              </div>
              {/* <div className="number">
                <strong>
                  {Math.round(isNaN(props.sales.sum) ? null : props.sales.sum)}
                </strong>
              </div> */}
            </div>
          </div>
          <div className="col-xl-4 col-sm-6 ">
            <div className="item d-flex align-items-center ">
              <div className="icon bg-red">
                <i className="icon-padnote"></i>
              </div>
              <div className="title">
                <span>
                  {/* Closed
                  <br /> 
                  Orders */}
                  Completed
                </span>
                <div className="number" style={{ color: "black" }}>
                  <strong>{props.closedOrders}</strong>
                </div>
                <div className="progress">
                  <div
                    role="progressbar"
                    style={{ width: "70%", height: "4px" }}
                    aria-valuenow="70"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    className="progress-bar bg-red"
                  ></div>
                </div>
              </div>
              {/* <div className="number">
                <strong>{props.closedOrders}</strong>
              </div> */}
            </div>
          </div>
          <div className="col-xl-4 col-sm-6">
            <div className="item d-flex align-items-center">
              <div className="icon bg-green">
                <i className="icon-bill"></i>
              </div>
              <div className="title">
                <span>
                  {/* Total
                  <br /> */}
                  Total Orders
                </span>
                <div className="number" style={{ color: "black" }}>
                  <strong>{props.totalOrders}</strong>
                </div>
                <div className="progress">
                  <div
                    role="progressbar"
                    style={{ width: "40%", height: "4px" }}
                    aria-valuenow="40"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    className="progress-bar bg-green"
                  ></div>
                </div>
              </div>
              {/* <div className="number">
                <strong>{props.totalOrders}</strong>
              </div> */}
            </div>
          </div>
          {/* <div className="col-xl-3 col-sm-6">
            <div className="item d-flex align-items-center">
              <div className="icon bg-orange">
                <i className="icon-check"></i>
              </div>
              <div className="title">
                <span>
                 All
                   <br /> 
                  Customers
                </span>
                <div className="number" style={{ color: "black" }}>
                  <strong>{props.users}</strong>
                </div>
                <div className="progress">
                  <div
                    role="progressbar"
                    style={{ width: "50%", height: "4px" }}
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    className="progress-bar bg-orange"
                  ></div>
                </div>
              </div>
              <div className="number">
                <strong>{props.users}</strong>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
    <br />
    {/* <td className="numbers">
                  <p>
                    No. of Orders:{" "}
                    <strong>
                      {isNaN(this.state.totalOrders)
                        ? null
                        : this.state.totalOrders}
                    </strong>
                    <br />
                    Gross Sale:{" "}
                    <strong>
                      {isNaN(this.state.totalSales)
                        ? null
                        : Math.round(this.state.totalSales)}{" "}
                      ₹
                    </strong>
                    <br />
                    Net Sale:{" "}
                    <strong>
                      {isNaN(this.state.netSales)
                        ? null
                        : Math.round(this.state.netSales)}{" "}
                      ₹
                    </strong>
                    <br />
                    APC:{" "}
                    <strong>
                      {isNaN(this.state.average)
                        ? null
                        : Math.round(this.state.average)}
                    </strong>
                  </p>
                </td> */}
    <section className="feeds no-padding-top">
      <div className="container-fluid">
        <div className="row">
          {/* <div className="col-lg-6">
          <h5>New API</h5>
        </div> */}
          <div className="col-lg-6">
            <div className="articles card">
              <div className="card-close">
                <div className="dropdown">
                  <button
                    type="button"
                    id="closeCard4"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="dropdown-toggle"
                  >
                    <i className="fa fa-ellipsis-v"></i>
                  </button>
                  <div
                    aria-labelledby="closeCard4"
                    className="dropdown-menu dropdown-menu-right has-shadow  "
                  >
                    <a href="/" className="dropdown-item remove">
                      <i className="fa fa-times"></i>Close
                    </a>
                    <a href="/" className="dropdown-item edit">
                      <i className="fa fa-gear"></i>Edit
                    </a>
                  </div>
                </div>
              </div>
              <div className="card-header d-flex align-items-center">
                <h2 className="h3">Today Sales </h2>
              </div>
              <div className="card-body no-padding ">
                <div className="item d-flex align-items-center ">
                  <div className="image"></div>
                  <div className="text">
                    <a href="/">
                      <h3 className="h5">
                        {isNaN(props.todaystotalOrders)
                          ? null
                          : Math.round(props.todaystotalOrders)}
                        {/* Rs.
                        {Math.round(props.cod)} */}
                      </h3>
                    </a>
                    <small>
                      <h5>Total Orders</h5>
                    </small>
                  </div>
                  <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  <div className="text">
                    <a href="/">
                      <h3 className="h5">
                        <i className={CURRENCY} aria-hidden="true"></i>
                        &nbsp;
                        {isNaN(props.todaystotalSales)
                          ? null
                          : Math.round(props.todaystotalSales)}
                        {/* Rs.
                        {Math.round(props.machine)} */}
                      </h3>
                    </a>
                    <small>
                      <h5>Gross Sale</h5>
                    </small>
                  </div>
                </div>
                <div className="item d-flex align-items-center">
                  <div className="image"></div>
                  <div className="text">
                    <a href="/">
                      <h3 className="h5">
                        <i className={CURRENCY} aria-hidden="true"></i>
                        &nbsp;
                        {isNaN(props.todaysnetSales)
                          ? null
                          : Math.round(props.todaysnetSales)}
                        {/* Rs.
                        {Math.round(props.paytm)} */}
                      </h3>
                    </a>
                    <small>
                      <h5>Net Sale</h5>
                    </small>
                  </div>
                  <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  <div className="text">
                    <a href="/">
                      <h3 className="h5">
                        {isNaN(props.todaysaverage)
                          ? null
                          : Math.round(props.todaysaverage)}
                        {/* Rs.
                        {Math.round(props.cancelledSales)} */}
                      </h3>
                    </a>
                    <small>
                      <h5>APC</h5>
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="articles card">
              <div className="card-close">
                <div className="dropdown">
                  <button
                    type="button"
                    id="closeCard4"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="dropdown-toggle"
                  >
                    <i className="fa fa-ellipsis-v"></i>
                  </button>
                  <div
                    aria-labelledby="closeCard4"
                    className="dropdown-menu dropdown-menu-right has-shadow"
                  >
                    <a href="/" className="dropdown-item remove">
                      <i className="fa fa-times"></i>Close
                    </a>
                    <a href="/" className="dropdown-item edit">
                      <i className="fa fa-gear"></i>Edit
                    </a>
                  </div>
                </div>
              </div>
              <div className="card-header d-flex align-items-center ">
                <h2 className="h3">All Orders </h2>
              </div>
              <div className="card-body no-padding">
                <div className="item d-flex align-items-center">
                  <div className="image"></div>
                  <div className="text">
                    <a href="/">
                      <h3 className="h5">{props.completed}</h3>
                    </a>
                    <small>
                      <h5>Completed </h5>
                    </small>
                  </div>
                  <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  <div className="text">
                    <a href="/">
                      <h3 className="h5">0{/* {props.accepted} */}</h3>
                    </a>
                    <small>
                      <h5>Accepted </h5>
                    </small>
                  </div>
                </div>
                <div className="item d-flex align-items-center">
                  <div className="image"></div>
                  <div className="text">
                    <a href="/">
                      <h3 className="h5">0{/* {props.pending} */}</h3>
                    </a>
                    <small>
                      <h5>Pending </h5>
                    </small>
                  </div>
                  <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  <div className="text">
                    <a href="/">
                      <h3 className="h5">0{/* {props.inprogress} */}</h3>
                    </a>
                    <small>
                      <h5>In-Progress</h5>
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default CardsComponent;
