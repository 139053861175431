import React from 'react'; // , { useState, useMemo, useCallback }
import logo from '../../images/logo.png';
// import DataListInput from "react-datalist-input";

// const LoginComponent = ({ myValues }) => {
//   // selectedItem
//   const [item, setItem] = useState();

//   /**
//    * your callback function gets called if the user selects one option out of the drop down menu
//    * @param selectedItem object (the selected item / option)
//    */
//   const onSelect = useCallback((selectedItem) => {
//     console.log("selectedItem", selectedItem);
//   }, []);

//   // the array you want to pass to the react-data-list component
//   // key and label are required properties
//   const items = useMemo(
//     () =>
//       myValues.map((oneItem) => ({
//         // required: what to show to the user
//         label: oneItem.name,
//         // required: key to identify the item within the array
//         key: oneItem.id,
//         // feel free to add your own app logic to access those properties in the onSelect function
//         someAdditionalValue: oneItem.someAdditionalValue,
//         // or just keep everything
//         ...oneItem,
//       })),
//     [myValues]
//   );

//   return (
//     <DataListInput
//       placeholder="Select an option from the drop down menu..."
//       items={items}
//       onSelect={onSelect}
//     />
//   );
// };
// // const onSelect = useCallback((selectedItem) => {
// //   console.log("selectedItem", selectedItem);
// // }, []);

// // // the array you want to pass to the react-data-list component
// // // key and label are required properties
// // const items = useMemo(
// //   () =>
// //     this.state.stores.map((oneItem) => ({
// //       // required: what to show to the user
// //       label: oneItem.name,
// //       // required: key to identify the item within the array
// //       key: oneItem.id,
// //       // feel free to add your own app logic to access those properties in the onSelect function
// //       // someAdditionalValue: oneItem.someAdditionalValue,
// //       // or just keep everything
// //       // ...oneItem,
// //     })),
// //   [this.state.stores]
// // );

const LoginComponent = (props) => {
  const handleEnter = (e) => {
    if (e.key.toLowerCase() === 'enter') {
      props.onLogin();
    }
  };
  return (
    <div className="page login-page">
      <div className="container d-flex align-items-center">
        <div className="form-holder has-shadow">
          <div className="row">
            {/* <!-- Logo & Information Panel--> */}
            <div className="col-lg-6">
              <div className="info d-flex align-items-center">
                <div className="content">
                  <div className="logo">
                    <img
                      src={logo}
                      alt="logo"
                      style={{ width: '30%', height: 'auto' }}
                    />
                    <h1>UGADI CAFE</h1>
                  </div>
                  <p>Amaze Deliver admin tool.</p>
                </div>
              </div>
            </div>
            {/* <!-- Form Panel    --> */}
            <div className="col-lg-6 bg-white">
              <div className="form d-flex align-items-center">
                <div className="content">
                  <form method="post" className="form-validate">
                    <div
                      // className="form-group"
                      className="d-flex align-items-stretch"
                    >
                      <div className="i-checks d-flex gap-3 align-items-center pb-4">
                        <input
                          id="radioCustom1"
                          type="radio"
                          value="employee"
                          name="a"
                          className="radio-template"
                          onChange={props.loginType}
                          defaultChecked
                        />
                        <label htmlFor="radioCustom1" className="mb-0  pr-4">
                          Super Admin
                        </label>

                        <input
                          id="radioCustom2"
                          type="radio"
                          value="store"
                          name="a"
                          className="radio-template "
                          onChange={props.loginType}
                        />
                        <label htmlFor="radioCustom2" className=" mb-0">
                          <span>Store</span>
                        </label>
                      </div>
                    </div>
                    {props.typeOfLogin === 'store' ? (
                      <div className="col-sm-9">
                        <select
                          className="form-control mb-3"
                          onChange={props.storeSelect}
                        >
                          <option value="" hidden>
                            select Store
                          </option>
                          {props.stores
                            ? props.stores.length !== 0 || props.stores !== null
                              ? props.stores.map((p, index) => (
                                  <option key={index} value={p.store.id}>
                                    {p.store.name}
                                  </option>
                                ))
                              : null
                            : null}
                        </select>
                        {/* <DataListInput
                          placeholder="Select an option from the drop down menu..."
                          items={props.stores}
                          onSelect={onSelect}
                        /> */}
                        {/* <div className="form-group">
                          <input
                            list="stores"
                            name="newType"
                            // onChange={props.storeSelect}
                            // onChange={(e) => props.storeSelect(e.target)}
                            // onSelect={(e) =>
                            //   props.storeSelect(
                            //     // e.currentTarget.getAttibute("key")
                            //     e
                            //   )
                            // }
                            // onChange={props.storeSelect}
                            onSelect={(e) =>
                              props.storeSelect(e.currentTarget.value)
                            }
                            className="form-control mb-3"
                            // readOnly
                          />
                          <datalist id="stores">
                            <option value="">select Store</option>
                            {props.stores
                              ? props.stores !== [] || props.stores !== null
                                ? props.stores.map((p, index) => (
                                    <option key={index} value={p.id}>
                                      // {/* {p.storeName} 
                                    </option>
                                  ))
                                : null
                              : null}
                          </datalist>
                        </div> */}
                      </div>
                    ) : null}
                    <div className="form-group">
                      <input
                        id="login-username"
                        type="text"
                        name="userName"
                        required
                        data-msg="Please enter your username"
                        className="input-material"
                        onChange={props.onFieldChange}
                        onKeyDown={handleEnter}
                      />
                      <label
                        htmlFor="login-username"
                        className="label-material"
                      >
                        User Name
                      </label>
                    </div>
                    <div className="form-group">
                      <input
                        id="login-password"
                        type="password"
                        name="password"
                        required
                        data-msg="Please enter your password"
                        className="input-material"
                        onChange={props.onFieldChange}
                        onKeyDown={handleEnter}
                      />
                      <label
                        htmlFor="login-password"
                        className="label-material"
                      >
                        Password
                      </label>
                    </div>

                    <span
                      id="login"
                      // href="/"
                      className="btn btn-primary"
                      onClick={props.onLogin}
                    >
                      Login
                    </span>
                    {/* <!-- This should be submit button but I replaced it with <a> for demo purposes--> */}
                  </form>
                  {/* <a href="#profile" className="forgot-pass">
                    Forgot Password?
                  </a> */}
                  {/* <br />
                  <small>Do not have an account? </small>
                  <a href="#profile" className="signup">
                    Signup
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyrights text-center">
        <p>
          Design by{' '}
          <a target="_" href="/" className="external">
            Amaze Deliver
          </a>
          {/* <!-- Please do not remove the backlink to us unless you support further theme's cp at https://bootstrapious.com/donate. It is part of the license conditions. Thank you for understanding :)--> */}
        </p>
      </div>
    </div>
  );
};

export default LoginComponent;
